export const config = {
  name: 'Digesti',
  email: 'hello@digestiplan.com',
  domain: 'digestiplan.com',
  IS_DEV: process.env.NODE_ENV === 'development',
  isPlasmicPreview: typeof window === 'undefined' ? false : /\/plasmic-host/.test(window.location.pathname),
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  },
  meta: {
    pixelId: process.env.META_PIXEL,
  },
  gtmToken: process.env.NEXT_PUBLIC_GTM_TOKEN as string,
  webAppUrl: 'https://app.digestiplan.com/en/sign-in',
};
