import esLocale from 'date-fns/locale/es';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';

import { AppProvider } from '~/AppProvider';
import { useLeadQuery } from '~/hooks/api/useLeadQuery';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLeadMutation } from '~/hooks/useLeadMutation';
import { useTracking } from '~/hooks/useTracking';

import { config } from '../config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nextConfig from '../next-i18next.config';


const SEO_PAGES = ['/', '/reviews'];

const MyApp = ({ Component, pageProps }: AppProps) => {
  const AppComponent = Component as unknown as FC;
  const { asPath, isLocaleDefault } = useAppRouter();

  // TODO: add other locales
  useEffect(() => {
    if (pageProps.locale === 'es-ES') {
      setDefaultOptions({ locale: esLocale });
    }
  }, [pageProps.locale]);

  return (
    <>
      <AppProvider>
        <AppComponent {...pageProps} />
        <VisitorTracking />
      </AppProvider>
      <Head>
        {SEO_PAGES.includes(asPath) ? (
          <>{isLocaleDefault ? null : <link rel='canonical' href={`https://www.${config.domain}${asPath}`} />}</>
        ) : (
          <meta name='robots' content='noindex' />
        )}
        <title>{`Lose weight and say goodbye to bloating, constipation, and digestion issues | ${config.name}`}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>
    </>
  );
};

const VisitorTracking = () => {
  const { id } = useAppLead();
  const { query } = useAppRouter();
  const { isCheckoutPage, isPlansOrCommitPage } = useAppRouter();
  const tracking = useTracking();
  const leadMutation = useLeadMutation();
  const leadId = query.leadCode as string;

  useLeadQuery(leadId);

  useEffect(() => {
    if (isCheckoutPage) {
      tracking.checkoutVisit();
    }

    if (isCheckoutPage && id) {
      leadMutation.mutate({ id, customFields: { initiatedCheckout: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckoutPage]);

  useEffect(() => {
    if (isPlansOrCommitPage) {
      tracking.plansVisit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlansOrCommitPage]);

  useEffect(() => {
    tracking.sessionInit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default appWithTranslation(MyApp, i18nextConfig);
