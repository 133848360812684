import addMonths from 'date-fns/addMonths';
import formatDate from 'date-fns/format';

import {
  normalizeParams,
  inchesToFeets,
  feetsToInches,
  round,
  lbToKg,
  inchesToCm,
  getWeightDiff,
  toNumber,
} from './helpers';

interface SummarizeParams {
  age?: number;
  height?: number;
  weight: number;
  targetWeight?: number;
}

interface SummarizeOptions {
  isMetric?: boolean;
  pointNotation?: number;
}

const DEFAULT_OPTIONS: SummarizeOptions = {
  isMetric: false,
  pointNotation: 0,
};

export const summarize = (params: SummarizeParams, options = DEFAULT_OPTIONS) => {
  const { height, weight, targetWeight, age } = normalizeParams<SummarizeParams>(params);
  const opts = { ...DEFAULT_OPTIONS, ...options };
  const isMetricsUnitSystem = opts.isMetric;
  const pointNotation = opts.pointNotation;

  const heightNormalized = isMetricsUnitSystem ? inchesToCm(height) : height;
  const weightNormalized = isMetricsUnitSystem ? lbToKg(weight) : weight;
  const targetWeightNormalized = isMetricsUnitSystem ? lbToKg(targetWeight) : targetWeight;

  return {
    age,
    height: round(heightNormalized, pointNotation),
    heightImperial: {
      feets: inchesToFeets(height),
      inches: round(height - feetsToInches(inchesToFeets(height)), isMetricsUnitSystem ? 1 : 0),
    },
    weight: round(weightNormalized, pointNotation),
    targetWeight: round(targetWeightNormalized, pointNotation),
    weightDifference: round(weightNormalized - targetWeightNormalized, pointNotation),
    units: {
      weight: isMetricsUnitSystem ? 'kg' : 'lb',
    },
    bmi: getBMISummary(weight, height),
    bmr: getBMRSummary(weight, height, age),
    forecast: getForecastDetails(weight, targetWeight, { isMetric: isMetricsUnitSystem }),
  };
};

/**
 * Calculates Body mass index (BMI)
 * @param weight number
 * @param height number
 * @returns object
 */
export const getBMISummary = (weight: number, height: number) => {
  const score = round(toNumber(toNumber(weight) / toNumber(height) ** 2) * 703, 1);

  const getCategoryAndKey = () => {
    if (score <= 18.5) {
      return {
        range: '< 18.5',
        category: 'Underweight',
        key: 'underweight',
      };
    } else if (score >= 18.5 && score <= 24.9) {
      return {
        range: '18.5 - 24.9',
        category: 'Normal',
        key: 'normal',
      };
    } else if (score > 25 && score <= 29.9) {
      return {
        range: '25 - 30',
        category: 'overweight',
        key: 'overweight',
      };
    }

    return {
      range: '> 30',
      category: 'Obese',
      key: 'obese',
    };
  };

  return {
    score,
    ...getCategoryAndKey(),
  };
};

/**
 * Calculates Basal Metabolic Rate (BMR) by Mifflin St Jeor Equation formula
 * [Learn more](https://www.omnicalculator.com/health/bmr)
 *
 * @param weight number
 * @param height number
 * @param age number
 * @returns object
 */
export const getBMRSummary = (weight: number, height: number, age: number) => {
  const perDay = Math.abs(Math.round(10 * lbToKg(weight) + 6.25 * inchesToCm(height) - 5 * toNumber(age) - 161));

  return {
    perDay,
  };
};

/**
 *
 * @param weight number
 * @param targetWeight number
 * @param options object
 * @returns object
 */
export const getForecastDetails = (weight: number, targetWeight: number, options?: { isMetric?: boolean }) => {
  const diff = getWeightDiff(weight, targetWeight);
  const afterFirstMonth = calcFirstMonthWeightLoss(weight, targetWeight);

  const durationInMonths = Math.ceil(diff / afterFirstMonth) || 1;

  return {
    afterFirstMonth: options?.isMetric ? lbToKg(afterFirstMonth) : afterFirstMonth,
    durationInMonths,
    getGoalDate: (format: 'MMM d, yyyy' | 'MMMM') => {
      return formatDate(addMonths(new Date(), durationInMonths), format);
    },
  };
};

/**
 *
 * @param weight number
 * @param targetWeight number
 * @returns number
 */
export const calcFirstMonthWeightLoss = (weight: number, targetWeight: number): number => {
  const diff = getWeightDiff(weight, targetWeight);

  if (diff - 10 <= 0) {
    return diff;
  } else if (diff <= 14) {
    return 10;
  } else if (diff < 22) {
    return 14;
  } else if (diff < 33) {
    return 18;
  } else if (diff < 44) {
    return 22;
  } else {
    return 26;
  }
};

export default summarize;
